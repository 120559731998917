import axios from "axios";

let loggedin = localStorage.getItem("dkpLoggedin");
const baseUrl = "";
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    loggedin = localStorage.getItem("dkpLoggedin");
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (loggedin && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(`${baseUrl}/auth/token/refresh/`, { withCredentials: true })
        .then((res) => {
          if (res.status === 200) {
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);
//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup/`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login/`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/token/refresh/`, body);
  },
  logout: (body) => {
    return axios.post(`${baseUrl}/auth/logout/`, body);
  },
  getUserDetail: () => {
    return axios.get(baseUrl + "/api/user_detail/", { withCredentials: true });
  },
  getSearchItems: (filterString) => {
    return axios.get(baseUrl + "/api/search/" + filterString, {
      withCredentials: true,
    });
  },
  getSearchItemCategories: () => {
    return axios.get(baseUrl + "/api/search_categories/", {
      withCredentials: true,
    });
  },
  getUseCases: () => {
    return axios.get(baseUrl + "/api/use_cases/", {
      withCredentials: true,
    });
  },
  getUseCase: (uuid) => {
    return axios.get(baseUrl + "/api/use_case/?uuid=" + uuid, {
      withCredentials: true,
    });
  },
  getDataItems: (filterString) => {
    return axios.get(baseUrl + "/api/data_items/" + filterString, {
      withCredentials: true,
    });
  },
  getDataItemDetail: (uuid) => {
    return axios.get(baseUrl + "/api/data_item/?uuid=" + uuid, {
      withCredentials: true,
    });
  },
  getDataItemCategories: () => {
    return axios.get(baseUrl + "/api/data_item_categories/", {
      withCredentials: true,
    });
  },
  getMetrics: (filterString) => {
    return axios.get(baseUrl + "/api/metrics/" + filterString, {
      withCredentials: true,
    });
  },
  getBrowseMetrics: (filterString) => {
    return axios.get(baseUrl + "/api/browse_metrics/" + filterString, {
      withCredentials: true,
    });
  },
  searchMetrics: (filterString) => {
    return axios.post(baseUrl + "/api/search_metrics/" + filterString, {
      withCredentials: true,
    });
  },
  searchMetricsCategories: (filterString) => {
    return axios.post(
      baseUrl + "/api/search_metrics_categories/" + filterString,
      {
        withCredentials: true,
      }
    );
  },
  getMetricCategories: () => {
    return axios.get(baseUrl + "/api/metric_categories/", {
      withCredentials: true,
    });
  },
  getMetricTypes: () => {
    return axios.get(baseUrl + "/api/metric_types/", {
      withCredentials: true,
    });
  },
  getMetricDetail: (uuid) => {
    return axios.get(baseUrl + "/api/metric/?uuid=" + uuid, {
      withCredentials: true,
    });
  },
  chatbotMessage: (body) => {
    return axios.post(`${baseUrl}/api/chatbot/`, body, {
      withCredentials: true,
    });
  },
};
export default api;
