import React, { useState, useEffect } from "react";
import './Detail.css';
import api from "../api/api";

// Import external components
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col, Table } from 'react-bootstrap';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

// functions 
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default function MetricDetail() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    // app state
    const [appState, setAppState] = useState({
        loading: true
    });
    const size = useWindowSize();
    const [metric, setMetric] = useState([]);

    // param
    let { metricUUID } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const metricDetailRes = await api.getMetricDetail(metricUUID);
                setMetric(metricDetailRes.data);
                setAppState({
                    ...appState,
                    loading: false,
                });
            } catch (error) {
                // login
                console.log(error);
            }
        })();
    }, []);

    // styles
    const leftPad = {
        marginLeft: '4px',
    };
    const rightPad = {
        marginRight: '4px',
    };
    const bothPad = {
        marginLeft: '4px',
        marginRight: '4px',
    };
    const topPad5 = {
        marginTop: '5px',
    };
    const topPad6 = {
        marginTop: '6px',
    };
    const topPad8 = {
        marginTop: '8px',
    };
    const topPad12 = {
        marginTop: '12px',
    };
    const topPad = {
        marginTop: '20px',
    };

    function getNumberOfDataItems(item) {
        if (item.data_items) {
            return item.data_items.length;
        }
        else {
            return 0;
        }
    }

    return (
        <Container fluid className="MetricDetail">
            <Row style={bothPad}>
                <h5 class="text-primary">Metric</h5>
                <h4>{metric.name}</h4>
                <Col xs={6} style={{ position: 'relative', height: size.height - 200, overflow: 'scroll' }}>
                    <Table borderless>
                        <tbody>
                            <tr>
                                <td><b>Methodology:</b></td>
                                <td>{metric.methodology ? metric.methodology : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Metric Type:</b></td>
                                <td>{metric.metric_types ? metric.metric_types : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Asset classes</b></td>
                                <td>{metric.asset_classes ? metric.asset_classes.join(', ') : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Other asset class(es)</b></td>
                                <td>{metric.asset_classes_if_other ? metric.asset_classes_if_other.join(', ') : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Aggregation level / Resolution:</b></td>
                                <td>{metric.aggregation_level ? metric.aggregation_level : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Time horizon:</b></td>
                                <td>{metric.time_horizon ? metric.time_horizon : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Additional comments:</b></td>
                                <td>{metric.additional_comments ? metric.additional_comments : '-'}</td>
                            </tr>
                        </tbody>
                    </Table>

                </Col>
                <Col xs={6}>
                    <Card className="itemDetail">
                        <Card.Body>
                            <Row>
                                <Col md="auto" className="alignBottom"><h1 class="text-primary">{getNumberOfDataItems(metric)}</h1></Col>
                                <Col className="alignBottom"><h6>data item{getNumberOfDataItems(metric) != 1 ? "s" : ""} {getNumberOfDataItems(metric) != 1 ? "are" : "is"} used to construct this metric.</h6></Col>
                            </Row>
                            <Row>
                                <Col style={{ marginLeft: '15px', marginRight: '15px', position: 'relative', height: size.height - 250, overflow: 'scroll' }}>
                                    { metric.data_items && (
                                        <React.Fragment>
                                            {
                                                metric.data_items.map((item) =>
                                                    <Card className="detailCard" onClick={e => window.location.href = '/data-item-detail/' + item.uuid}>
                                                        <Card.Body>
                                                            <h6>Data item: {item.name}</h6>
                                                            <p><em>Description: {item.description}</em></p>
                                                            <p><em>Data provider: {item.data_provider_name ? item.data_provider_name : '-'}</em></p>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}