// SuggestNew component - for users to suggest new metric or data item

// Import external components
import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";

// Import images and icons
import { BsSearch } from 'react-icons/bs';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function SuggestNew() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const [appState, setAppState] = useState({
        display: "hide",
        loading: false,
    });

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad8 = {
        marginTop: '5px',
    };
    const topPad = {
        marginTop: '20px',
    };
    const primaryColor = {
        color: '#1496A5',
    }

    let { item } = useParams();

    return (
        <Container fluid className="SuggestNew" style={bothPad}>
            {item != "data-item" && item != "metric" && (
                <Row>
                    <h5 className="text-primary">Placeholder for suggesting new item</h5>
                </Row>
            )}
            {item == "metric" && (
                <Row>
                    <h5 className="text-primary">Placeholder for suggesting new metric</h5>
                </Row>
            )}
            {item == "data-item" && (
                <Row>
                    <h5 className="text-primary">Placeholder for suggesting new data item</h5>
                </Row>
            )}
        </Container>
    )
}