import React, { useState, useEffect } from "react";
import api from "../api/api";
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import './Browse.css';

export default function BrowseMetrics() {
    // data state to store API results, initial value is an empty array
    const [data, setData] = useState([]);

    // Using useEffect to call the API once mounted and set the data
    useEffect(() => {
        (async () => {
            const result = await api.getMetricTypes();
            setData(result.data);
        })();
    }, []);

    const listMetricTypes = data.map((item) =>
        <Col md="3" style={{ marginTop: '15px' }}>
            <div className="itemCard" style={{ background: '#cbe0c8', height: '6rem', width: '13rem' }} onClick={e => window.location.href="/browse-filter-metrics/?metric_type="+encodeURIComponent(item)}><h5><b>{item}</b></h5></div>
        </Col>
    );

    return (
        <Container>
            <Row>
                {listMetricTypes}
            </Row>
        </Container>
    )
}