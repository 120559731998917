import React, { useState, useEffect } from "react";
import api from "../api/api";

// Import external components
import { useParams, useSearchParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Badge,
  Table,
} from "react-bootstrap";

// Matomo
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default function FilterUseCaseSubcategory() {
  // Matomo
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  React.useEffect(() => {
    trackPageView();
  }, []);

  const [appState, setAppState] = useState({
    display: "hide",
    loading: false,
  });

  // data state to store API results, initial value is an empty array
  const [subcategories, setSubCategories] = useState([]);
  const [use_case_name, setUseCaseName] = useState([]);

  let { useCaseUUID } = useParams();
  let [filterDataItemParams, setFilterDataItemParams] = useSearchParams();

  // Using useEffect to call the API once mounted
  useEffect(() => {
    (async () => {
      const result = await api.getUseCase(useCaseUUID);
      setSubCategories(result.data.subcategories);
      setUseCaseName(result.data.name);
    })();
  }, []);

  const getSubCategories = () => {
    let subcat = "&use_case_subcategory=";
    subcategories.map((item) => {
      subcat += encodeURIComponent(item.name) + "|";
    });
    return subcat;
  };

  const listSubcategories = subcategories.map((item, idx) => (
    <Card key={idx} bg="light" className="shadow" style={{ marginTop: "15px" }}>
      <Card.Body>
        <h6>Subcategory: {item.name.replace(" - " + use_case_name, "")}</h6>
        <a
          href={
            "/browse-filter-usecase-metrics?use_case=" +
            encodeURIComponent(use_case_name) +
            "&use_case_subcategory=" +
            encodeURIComponent(item.name)
          }
        >
          See all metrics for this subcategory
        </a>
      </Card.Body>
    </Card>
  ));

  // styles

  const leftPad = {
    marginLeft: "20px",
  };
  const rightPad = {
    marginRight: "20px",
  };
  const bothPad = {
    marginLeft: "20px",
    marginRight: "20px",
  };
  const topPad5 = {
    marginTop: "5px",
  };
  const topPad8 = {
    marginTop: "8px",
  };
  const topPad = {
    marginTop: "20px",
  };
  const primaryColor = {
    color: "#1496A5",
  };

  return (
    <Container fluid className="FilterUseCaseSubcategory">
      <Row>
        <h4>Use Case: {use_case_name}</h4>
        <a
          href={
            "/browse-filter-usecase-metrics/?use_case=" +
            encodeURIComponent(use_case_name) +
            getSubCategories() +
            "&all_subcategories=" + "true"
          }
        >
          See all metrics for this use case
        </a>
      </Row>
      <Row style={bothPad}>{listSubcategories}</Row>
    </Container>
  );
}
