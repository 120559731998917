import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
// variables to switch for NGFS and green data repository
import loginLogo from "../Images/ngfsLogo.svg";

export default function Disclaimer(props) {
  //state

  return (
    <div class="backgroundImage d-flex align-items-center justify-content-center">
      <Card style={{ width: "35rem", borderColor: "#000000" }}>
        <Card.Body>
          <div className="text-center">
            <img src={loginLogo} width={180} />
            <br />
            <br />
          </div>
          <div>
            <b>Mandatory confidentiality provisions:</b>
            <p>
              {/* By entering the repository, I acknowledge that I am aware the contents of the repository is <u><b>confidential. </b></u> I hereby commit to using the repository <u><b>for consultation purposes only</b></u>, i.e. I commit to <u><b>neither share nor extract contents</b></u> from the repository. */}
              Please note that this web interface and its content are{" "}
              <u>
                <b>confidential.</b>
              </u>{" "}
              Therefore, by participating to this consultation, you commit to
              using the directory for this consultation purposes only.
            </p>
          </div>
          <div className="text-center">
            <Button className="center" onClick={props.setDisclaimer}>
              Enter Directory
            </Button>
          </div>
          <div>
            <b>Survey</b>
            <p>
              Your feedback is greatly appreciated as it will help us improve
              the directory. Please provide your feedback{" "}
              <a
                target="_blank"
                href="https://www.banque-france.fr/en/webform/survey-consultation-ngfs-directory"
              >
                here
              </a>
              !
            </p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
