import React, { useState, useEffect } from "react";
import './Filter.css';
import api from "../api/api";

// Import external components
import { useSearchParams } from "react-router-dom";
import { Accordion, Button, Container, Row, Col, Form, Card, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

// functions 
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default function FilterSearch() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const size = useWindowSize();

    // app state
    const [appState, setAppState] = useState({
        loading: true
    });
    const [categories_loading, setCategoriesLoading] = useState([false]);

    // regex variables
    const slash_regex = /(\S)\/(\S)/g;

    // data states
    const [search_item_count, setSearchItemCount] = useState([]);
    const [search_items, setSearchItems] = useState([]);
    const [filter_categories, setFilterCategories] = useState([]);
    const [filter_count, setFilterCount] = useState([0]);
    const [previous_query_length, setPreviousQueryLength] = useState([0]);

    // filter form state and handlers
    const [filters, setFilters] = useState([]);

    // oiginal filter from browsing
    let [originalFilterSearchItemParams, setFilterSearchItemParams] = useSearchParams();

    const handleQueryChange = (event) => {
        // update query state
        let search_item_params = filters.search_item_params;
        search_item_params.set('query', event.target.value);
        let previous_length = parseInt(previous_query_length);
        let current_length = event.target.value.length;
        if (previous_length == 0 && current_length > 0) {
            setFilterCount(parseInt(filter_count) + 1);
        }
        else if (previous_length > 0 && current_length == 0) {
            setFilterCount(parseInt(filter_count) - 1);
        }
        setPreviousQueryLength(event.target.value.length);
        setFilterSearchItemParams(search_item_params);
        setFilters({
            ...filters,
            search_item_params: search_item_params
        });
    }

    const handleFormChange = (event) => {
        // function to update filter categories
        let currentParamValue = '';
        let currentFormStateParamValue = '';
        let categoryValues = [];
        // get form state
        let search_item_params = filters.search_item_params;
        let form_state = filters.form_state;
        if (event.target.checked) {
            // current value has been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) + 1);
            if (search_item_params.has(event.target.id)) {
                // if so, add current value to category
                currentParamValue = search_item_params.get(event.target.id) + '|' + event.target.name;
                search_item_params.set(event.target.id, currentParamValue);
                // form state values are bookended with '+' to avoid filtering clas
                currentFormStateParamValue = '+' + search_item_params.get(event.target.id).split('|').join('+|+') + '+|+' + event.target.name + '+';
                form_state[event.target.id] = currentFormStateParamValue;
            }
            else {
                // if not, set new category
                search_item_params.append(event.target.id, event.target.name);
                form_state[event.target.id] = '+' + event.target.name + '+';
            }
        }
        else if (!event.target.checked) {
            // current value has not been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) - 1);
            if (search_item_params.has(event.target.id)) {
                categoryValues = search_item_params.get(event.target.id).split('|');
                var index = categoryValues.indexOf(event.target.name);
                if (index !== -1) {
                    categoryValues.splice(index, 1);
                }
                currentParamValue = categoryValues.join('|');
                search_item_params.set(event.target.id, currentParamValue);
                currentFormStateParamValue = '+' + categoryValues.join('+|+') + '+';
                form_state[event.target.id] = currentFormStateParamValue;
                if (search_item_params.get(event.target.id).length < 1) {
                    // delete category
                    search_item_params.delete(event.target.id);
                    form_state[event.target.id] = '';
                }
            }
        }
        // update form state
        setFilters({
            ...filters,
            form_state: form_state,
            search_item_params: search_item_params
        });
        // call API
        setFilterSearchItemParams(search_item_params);
        handleFilter(search_item_params);
    }

    const handleFilter = (query) => {
        setAppState({
            ...appState,
            loading: true,
        });
        let current_count = 0;
        query.forEach(function (value, key) {
            value.split('|').forEach(function (item) {
                if (item.length > 0) {
                    current_count++;
                }
            });
        });
        setFilterCount(current_count);
        api.getSearchItems("?" + query.toString())
            .then((result) => {
                setSearchItems(result.data.results);
                setSearchItemCount(result.data.count);
                setAppState({
                    ...appState,
                    loading: false
                });
            }, (error) => {
                console.log(error);
            })
            ;
    };

    const clearFilters = () => {
        let form_state = {
            'query': '',
            'type': '',
            'use_case': '',
            'use_case_subcategory': '',
            'aggregation_level': '',
            'time_horizon': ''
        };
        let search_item_params = new URLSearchParams();
        setFilters({
            ...filters,
            form_state: form_state,
            search_item_params: search_item_params
        });
        setFilterCount(0);
        // call API
        setFilterSearchItemParams(search_item_params);
        handleFilter(search_item_params);
    }

    useEffect(() => {
        (async () => {
            try {
                let form_state = {
                    'query': '',
                    'type': '',
                    'use_case': '',
                    'use_case_subcategory': '',
                    'aggregation_level': '',
                    'time_horizon': ''
                };
                let search_item_params = new URLSearchParams();
                let current_count = parseInt(filter_count);
                originalFilterSearchItemParams.forEach(function (value, key) {
                    form_state[key] = '+' + value.split('|').join('+|+') + '+';
                    search_item_params.set(key, value);
                    value.split('|').forEach(function (item) {
                        if (item.length > 0) {
                            current_count++;
                        }
                    });

                });
                setFilterCount(current_count);
                setFilters({
                    ...filters,
                    form_state: form_state,
                    search_item_params: search_item_params
                });
                const searchItemCategoriesRes = await api.getSearchItemCategories();
                const categories = searchItemCategoriesRes.data;
                await handleFilter(originalFilterSearchItemParams);
                setFilterCategories(categories);
                setCategoriesLoading(false)

            } catch (error) {
                // login
                console.log(error);
            }
        })();
    }, []);

    const itemRoutes = {
        'Use Case': '/filter-use-case/',
        'Metric': '/metric-detail/',
        'Data Item': '/data-item-detail/'
    }

    const listSearchItems = search_items.map((item) =>
        // <Card className="filterItemCard" style={{ marginTop: '15px' }} onClick={e => window.location.href = itemRoutes[item.type.replace('NGFS BDG ', '')] + item.uuid}>
        <Card className="filterItemCard" style={{ marginTop: '15px' }} onClick={e => window.open(itemRoutes[item.type.replace('NGFS BDG ', '')] + item.uuid, '_blank').focus() }>
            <Card.Body>
                <h5>{item.type.replace('NGFS BDG ', '')}: {item.name}</h5>
                <Row style={{ fontSize: 12 }}>
                {item.type === 'NGFS BDG Data Item' && 
                    <Col>Description: {item.description ? item.description : '-'}</Col>
                }               
                    {item.type === 'NGFS BDG Data Item' && 
                        <Col>Provider: {item.data_provider_name ? item.data_provider_name : '-'}</Col>
                    }
                    <Col>Aggregation Level: {item.aggregation_level ? item.aggregation_level : '-' }</Col>
                    <Col>Time Horizon: {item.time_horizon ? item.time_horizon : '-' }</Col>
                    {item.type !== 'NGFS BDG Data Item' && 
                        <Col>Asset classes: {item.asset_classes ? item.asset_classes : '-' }</Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    );


    // styles

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad5 = {
        marginTop: '5px',
    };
    const topPad8 = {
        marginTop: '8px',
    };
    const topPad = {
        marginTop: '20px',
    };

    return (
        <Container fluid className="FilterSearchItems">
            <Row>
                {appState.loading && (
                    <Row style={{ marginLeft: '80px' }}>
                        <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24px' }} />
                    </Row>
                )}
                {!appState.loading && (
                    <Form onSubmit={handleFormChange}>
                        <Row style={{ marginLeft: '80px', marginRight: '80px', marginTop: '10px', marginBottom: '20px' }}>
                            <Col md="auto"><BsSearch size={24} style={topPad5} /></Col>
                            <Col><Form.Control type="text" name='query' placeholder="Search" value={filters.search_item_params.get('query')} onChange={handleQueryChange} /></Col>
                            <Col md="auto">
                                <Button className="text-center" variant="primary" onClick={handleFormChange}>
                                    <span style={{ color: "white" }}>Search</span>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Row>
            <Row>
                <Col xs={3} style={{ position: 'relative', height: size.height - 100, overflow: 'scroll' }}>
                    {categories_loading && (
                        <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24px' }} />
                    )}
                    {!categories_loading && (
                        <Form>
                            <Row>
                                <Col>
                                    <h4>{filter_count} Filter{filter_count != 1 ? "s" : ""}</h4>
                                </Col>
                                <Col style={{ marginTop: '4.5px' }}>
                                    {filter_count > 0 && (
                                        <a href="#" onClick={clearFilters}>Clear filter{filter_count != 1 ? "s" : ""}</a>
                                    )}
                                    {filter_count == 0 && (
                                        <span style={{ color: 'grey' }}>Clear filters</span>
                                    )}
                                </Col>
                            </Row>
                            <Accordion defaultActiveKey="0" style={{ marginTop: '16px' }}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Type</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_Types">
                                            {
                                                filter_categories.types.filter(x=> x !== 'Use Case').map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="type"
                                                        name={item}
                                                        label={item}
                                                        checked={filters.form_state.type.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Use Case</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_UseCases">
                                            {
                                                filter_categories.use_cases.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="use_case"
                                                        name={item}
                                                        label={item}
                                                        checked={filters.form_state.use_case.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Use Case Subcategory</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_UseCaseSubcategories">
                                            {
                                                filter_categories.use_case_subcategories.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="use_case_subcategory"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.use_case_subcategory.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Aggregation Level / Resolution</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_AggregationLevel">
                                            {
                                                filter_categories.aggregation_levels.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="aggregation_level"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.aggregation_level.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Time Horizon</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_TimeHorizon">
                                            {
                                                filter_categories.time_horizons.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="time_horizon"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.time_horizon.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Form>
                    )}
                </Col>

                <Col xs={9} style={{ position: 'relative', height: size.height - 100, overflow: 'scroll' }}>
                    <Row style={bothPad}>
                        {appState.loading && (
                            <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24px' }} />
                        )}
                        {!appState.loading && (
                            <React.Fragment>
                                <Row>
                                    <h4>{search_item_count} Result{search_item_count != 1 ? "s" : ""}</h4>
                                </Row>
                                {listSearchItems}
                            </React.Fragment>
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}