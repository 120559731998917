import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";

// Import images and icons
import { BsSearch } from 'react-icons/bs';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function Glossary() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const [appState, setAppState] = useState({
        loading: false,
    });

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
        width:'98%'
    };
    const topPad8 = {
        marginTop: '5px',
    };
    const topPad = {
        marginTop: '20px',
    };
    const primaryColor = {
        color: '#1496A5',
    }

    return (
        <Container fluid className="Glossary" style={bothPad}>
            {/*Glossary of use cases*/}
            <h5 className="text-primary">Glossary of use cases</h5>
            <br/>
            <h6 className="text-primary">Exposure quantification</h6>
            <p>The measurement of the maximum potential loss on loans, bonds, equity and other financial instruments provided by a credit
               institution, asset manager, insurer (asset side) or other buy-side entity (for example, pension fund, hedge fund, etc.)
               to a counterparty, as a result of climate-related hazards.
            </p>
            <h6 className="text-primary">Investment and lending decisions</h6>
            <p>The decision made by a credit institution, asset manager, insurer (asset side) or other buy-side entity
               (for example pension fund, hedge fund, etc.) on the amount of funds to be deployed in investment opportunities
               such as bonds and equity, or to provide/refinance a loan. 
               Climate-related risks and opportunities may change the profile of an organization’s debt and equity structure, 
               either by increasing debt levels to compensate for reduced operating cash flows or for new capital expenditures or R&D. 
               It may also affect the organization’s ability to raise new debt or refinancing existing debt, or to reduce the tenors 
               available to it. There could also be changes to capital and reserves from operating losses, asset write-downs or 
               the need to raise new equity to meet investments.<sup><a href="#fn1">1</a></sup> 
            </p>
            <h6 className="text-primary">Macroeconomic modelling</h6>
            <p>Macroeconomic modelling is used to analyse the impacts of climate-related issues on macroeconomic indicators like GDP,
               employment and inflation. A typical example of the need for macroeconomic modelling is policy analysis, or the analysis
               of the macroeconomic impacts of climate change. In the case of policy analysis, the impacts of policies to mitigate or
               adapt to climate change are observed. The policies can concern, for instance, investment decisions on energy transformation
               or innovation, measures related to the industrial structure or competitiveness of an industry<sup><a href="#fn2">2</a></sup>.
               &nbsp;In turn, analysis of the
               macroeconomic impacts of climate change concern output, GDP and inflation, for instance, or potential supply and
               demand shocks.
            </p>
            <p>Economic growth analysis is one example of macroeconomic analysis, which can be used to observe, for instance,
               how climate change impacts on GDP, output, economic activity and industries in the long term.
               It can also be used to observe what effects climate-related policy measures such as government investments have on growth.
            </p>
            <h6 className="text-primary">Financial stability monitoring</h6>
            <p>Financial stability monitoring consists of the assessment of financial systems vulnerabilities,
               defined as the collection of factors that contribute to the potential for widespread externalities. For the purpose
               of financial stability monitoring, transition risks and physical risks related to climate change are observed from the
               macroeconomic point of view. It is essential to recognize the systemic risks and multiple transmission channels
               (direct and indirect) of climate change-related risks and its control to economy and finances. Transmission channels
               are the ways in which economies, industries/sectors, households and individual companies are exposed to risks related 
               to climate change<sup><a href="#fn3">3</a></sup>.  Examples of exposure include the mispricing of assets such as securities or loans owing to transition 
               and physical risks, for example mispricing of carbon-intensive corporations’ securities owing to transition risks or 
               mispricing of real estate loans owing to a physical risk related to future flood risk. 
            </p>
            <h6 className="text-primary">Climate-related disclosures</h6>
            <p>Climate-related disclosures are reports by financial and non-financial corporations about climate-related factors, 
               including indicators such as their carbon footprint. The climate-related disclosures of individual An important foundation 
               for economic analysis, modelling, and monitoring are the climate-related disclosures of individual corporations, which provide 
               the raw data for analysis. One of the prevailing challenges of reliable analysis/modelling/monitoring is the absence of good 
               quality and comparable data from corporations, i.e. the climate-related disclosures, including indicators such as the carbon 
               footprint<sup><a href="#fn4">4</a></sup>.  One example of such definitions is the TCFD Recommendations issued by the Financial 
               Stability Board (FSB).
            </p>
            <h6 className="text-primary">Scenario analysis and stress testing</h6>
            <br/>
            <h6>-	Scenario analysis</h6>
            <p>Scenario analysis<sup><a href="#fn5">5</a></sup> enables supervisors, financial institutions and central banks to explore the 
               impact of different possible 
               climate change pathways in four dimensions: institution-specific risks, system-wide risks, macroeconomic risks and risks to 
               central banks’ own balance sheets. It offers a flexible methodological framework that can reflect emerging issues that are 
               not considered in more traditional forecasting analysis. The dynamic nature of scenarios allows the interactions between 
               sectors and variables, as well as climate dynamics, to be reflected. Scenario analysis usually distinguishes between macro 
               and micro approaches.
            </p>
            <ul>
                <li>Macro (top-down) approaches assess the impact on financial portfolios by using high-level proxies for risk. 
                    The climate scenarios are first translated into economic variables (such as GDP, unemployment, interest rates, 
                    and real estate prices) and aggregate financial market variables (such as sovereign risk, credit spreads and 
                    financial market indices). These inputs can be used to estimate an adjusted risk profile 
                    (for example, the change in probability of default, loss given default or market prices) and revalue financial exposures. 
                    In general, in these types of exercises, macroeconomic models are used to translate the effects of transition risks on 
                    the economy and financial system.
                </li>
                <li>Micro (bottom-up) approaches assess the potential impact of climate variables on counterparties at a granular level. 
                    This first involves identifying the location and characteristics of the underlying exposure 
                    (household or company activities). Micro models (for example, cash flow models, natural catastrophe models) are then 
                    used to estimate the vulnerability of exposures to physical or transition risks. This analysis can take account of 
                    the ability and strategy of the counterparty to respond to these pressures over time. This counterparty-level information 
                    is then used to revalue the associated financial exposures (mortgage, equity, sovereign bond) based on the adjusted 
                    risk profile.
                </li>
            </ul>
            <p>In addition, although these are less common, hybrid approaches can also be adopted. In this way, a macro model can be 
               downscaled to sectoral level using climate risk variables (such as the level of emissions) as a proxy for risk. 
               Similarly, a micro-level assessment can be complemented with macro scenario variables to capture wider macroeconomic channels. 
               Finally, these types of scenario analysis exercises can be run at portfolio level.
            </p>
            <p>Some practical examples for which scenario analysis can be used include assessing the expected losses of an asset portfolio 
                if average temperature rises by 3ºC or the expected losses in an economy where floods and other extreme weather events occur 
                more frequently than before. For this kind of application, the availability of geographical coordinates data of assets such 
                as real estate loans or securities is relevant. 
            </p>
            <p>One practical example of macroprudential scenario analysis is to model the structural changes to the economy and its sectors 
               resulting from the transition to a more sustainable direction. In the macroprudential approach, it is also essential to 
               focus on defining the transmission channels through which the transition to sustainable economy is conveyed. 
            </p>
            <h6>-	Stress testing </h6>
            <p>Stress testing<sup><a href="#fn6">6</a></sup>  and sensitivity analysis are risk framework methods that focus on the sensitivity of portfolios and the impact 
               climate change (the likelihood and severity of the materialization of climate-related risks) has on exposures’ actual riskiness.
            </p>
            <ul>
                <li>Climate stress tests: assessment featuring fully-fledged scenarios that map out possible future development paths of 
                    transition variables (e.g. carbon prices), physical variables (temperature increases) and the related changes in macro 
                    variables (e.g. output in different sectors, GDP, unemployment) and financial variables (e.g. interest rates). 
                    These scenarios are then translated into changes in portfolios’ (risk) attributes. Stress testing can take place 
                    at the portfolio, industry or counterparty-level.
                </li>
                <li>Climate stress tests usually apply predefined climate scenarios (certain temperature pathways), for which, for instance, 
                    emissions reduction pathways associated with specific climate goals are established. The international scientific 
                    community has developed several databases for identifying climate pathways (i.e. 2ºC consistent) and the implied 
                    trajectories for economic variables and sectors. These mostly use Integrated Assessment Models, which combine insights 
                    from various disciplines into a single framework, using socioeconomic, energy and climate factors. Instead of looking 
                    at scenarios that satisfy certain temperature targets, climate stress can also be modelled through event-based shocks 
                    (for example, policy, technology and preference shocks). 
                </li>
                <li>Climate sensitivity analysis is a simpler exercise that does not use scenarios, but assesses changes in portfolios’ risk 
                    attributes by changing some of the inputs in financial models based on shading and classification of exposures into 
                    “green” versus “non-green” exposures (which determines an exposure’s vulnerability to climate-related events and policies).
                </li>
            </ul>
            <br/>
            {/*Glossary of metrics*/}
            <h5 className="text-primary">Glossary of metrics</h5>
            <br/>
            <h6 className="text-primary">Footprint</h6>
            <p>GHG emissions caused directly or enabled by an individual, event, organisation, service or product.</p>
            <h6 className="text-primary">Transition sensitivity</h6>
            <p>The disruption caused by adjusting to a low-carbon economy, which may be the result of policy changes, 
                technological innovation or social adaptation.
            </p>
            <h6 className="text-primary">Physical vulnerability</h6>
            <p>The direct damage to assets or property that may come about owing to a 
                changing climate (for example rise in sea levels) or extreme weather events
            </p>
            <h6 className="text-primary">Alignment</h6>
            <p>Tracks progress towards a 2°C world.</p>            
            <h6 className="text-primary">Mobilisation</h6>
            <p>Capture growth in green financing(i.e. scaling up green finance)</p>
            <h6 className="text-primary">Combined Metrics</h6>
            <p>Metrics aggregating a combination of the above metrics to provide insight 
                on the extent to which a firm manages environmental, social and governance issues.
            </p>
            <br/>
            <br/>
            {/* footnotes */}
            <sup id="fn1">1 Task Force on Climate-related Financial Disclosures,&nbsp; 
                <em><a href="https://assets.bbhub.io/company/sites/60/2020/10/FINAL-2017-TCFD-Report-11052018.pdf">
                Final Report, Recommendations of the Task Force on Climate-related Financial Disclosures,</a></em>
                &nbsp;June 2017.
            </sup>
            <br/>
            <sup id="fn2">2 Andersson, M., Baccianti, C. and Morgan, J.,&nbsp; 
                <em><a href="https://www.ecb.europa.eu/pub/pdf/scpops/ecb.op243~2ce3c7c4e1.en.pdf">“Climate change and the macro economy”
                </a></em>, 
                &nbsp;Occasional Paper Series, No 243, ECB, Frankfurt am Main, June 2020.
            </sup>
            <br/>
            <sup id="fn3">3 Network for Greening the Financial System,&nbsp; 
                <em><a href="https://www.ngfs.net/sites/default/files/medias/documents/ngfs_research_priorities_final.pdf">
                    The Macroeconomic and Financial Stability Impacts of Climate Change Research Priorities
                </a></em>, 
                &nbsp;Technical document, June 2020.

            </sup>
            <br/>
            <sup id="fn4">4 International Monetary Fund,&nbsp;
              <em><a href="https://www.imf.org/en/Publications/GFSR/Issues/2020/10/13/global-financial-stability-report-october-2020">
                  Global Financial Stability Report: Bridge to Recovery
              </a></em>, 
              &nbsp;October 2020.
            </sup>
            <br/>
            <sup id="fn5">5 Network for Greening the Financial System,&nbsp;
             <em><a href="https://www.ngfs.net/sites/default/files/medias/documents/ngfs_guide_for_supervisors.pdf">
                 Guide for Supervisors
             </a></em>,
             &nbsp;May 2020.
            </sup>
            <br/>
            <sup id="fn6">6 European Banking Authority,&nbsp; 
                <em><a href="https://www.eba.europa.eu/sites/default/documents/files/document_library/Publications/Discussions/2021/Discussion Paper on management and supervision of ESG risks for credit institutions and investment firms/935496/2020-11-02  ESG Discussion Paper.pdf">
                    On management and supervision of ESG risks for credit institutions and investment firms”
                </a></em>, 
                &nbsp;EBA Discussion Paper, October 2020.
            </sup>
        </Container>
    )
}