import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";

// Import images and icons
import { BsSearch } from 'react-icons/bs';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function AboutUs() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const [appState, setAppState] = useState({
        display: "hide",
        loading: false,
    });

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad8 = {
        marginTop: '5px',
    };
    const topPad = {
        marginTop: '20px',
    };
    const primaryColor = {
        color: '#1496A5',
    }

    return (
        <Row style={bothPad} className="AboutUs">
            <h5 className="text-primary">About Us</h5>
            <p>The&nbsp;
                <a href="https://www.ngfs.net/en">NGFS</a>,
                &nbsp;launched at the Paris One Planet Summit on 12 December 2017, is a group of central banks and supervisors,
                which on a voluntary basis are willing to share best practices and contribute to the development of environment and
                climate risk management in the financial sector, and to mobilize mainstream finance to support the transition toward a
                sustainable economy. The NGFS brings together 108 central banks and supervisors and 17 observers. Together, they represent
                five continents and over 85% of global greenhouse gas emissions, and are responsible for the supervision of all of the global
                systemically important banks and two thirds of global systemically important insurers. The NGFS is chaired by Mr Ravi Menon,
                Managing Director of the Monetary Authority of Singapore. The Secretariat, headed by Mr Jean Boissinot,
                is provided by Banque de France.
            </p>
            <br />
            <p>The&nbsp;
                <a href="https://www.ngfs.net/sites/default/files/media/2020/09/03/wsbdg_mandate_final.pdf">workstream on bridging the data gaps</a>
                &nbsp;was set up in 2020 to turn the recommendation No. 3 of the NGFS First comprehensive
                report “Call for action”, (April 2019) into practice. The co-chairs of this workstream are Mr Fabio Natalucci from the
                International Monetary Fund and Mr Patrick Amis from the Single Supervisory Mechanism/European Central Bank.
                A&nbsp;
                <a href="https://www.ngfs.net/sites/default/files/medias/documents/progress_report_on_bridging_data_gaps.pdf">
                    Progress report</a>
                &nbsp;on the work of the Workstream was published in May 2021.
            </p>
        </Row>
    )
}