import React, { useState, useEffect } from "react";
import './Filter.css';
import api from "../api/api";

// Import external components
import { useSearchParams,useLocation } from "react-router-dom";
import { Accordion, Container, Row, Col, Form, Card, Spinner, Pagination } from 'react-bootstrap';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

// functions 
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default function FilterUseCaseMetrics() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    const location = useLocation();
    let params = new URLSearchParams(location.search);
    //console.log("search-string:", location.search)
    const [browseFilterUseCaseParams, setBrowseFilterUseCaseParams] = useState(location.search)

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const size = useWindowSize();

    // app state
    const [appState, setAppState] = useState({
        loading: true
    });
    const [categories_loading, setCategoriesLoading] = useState([false]);

    // regex variables
    const slash_regex = /(\S)\/(\S)/g;

    // data states
    const [metric_count, setMetricCount] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [pageNo, setPageNo] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [nextPage, setNextPage] = useState("")
    const [prevPage, setPrevPage] = useState("")
    const [filter_categories, setFilterCategories] = useState([]);
    const [filter_count, setFilterCount] = useState([0]);
    const [filter_metric_types, setFilterMetricTypes] = useState([]);
    const [filter_aggregationLevel, setFilterAggregationLevel] = useState([]);
    const [filter_time_horizon, setFilterTimeHorizon] = useState([]);
    const [filter_useCase_subcategories, setFilterUseCaseSubcategories] = useState([]);
    const [filter_useCase, setFilterUseCase] = useState([]);
    const [items, setItems] = useState([]);

    // filter form state and handlers
    const [filters, setFilters] = useState([]);

    // oiginal filter from browsing
    let [originalFilterMetricParams, setFilterMetricParams] = useSearchParams();

    const handleFormChanges = (event) => {
        // function to update filter categories
        let currentParamValue = '';
        let currentFormStateParamValue = '';
        let categoryValues = [];
        // get form state
        let metric_params = filters.metric_params;
        let form_state = filters.form_state;
        
        if (event.target.checked) {
            // current value has been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) + 1);
            if (metric_params.has(event.target.id)) {
                // if so, add current value to category
                currentParamValue = metric_params.get(event.target.id) + '|' + event.target.name;
                metric_params.set(event.target.id, currentParamValue);
                // form state values are bookended with '+' to avoid filtering clashes
                currentFormStateParamValue = '+' + metric_params.get(event.target.id).split('|').join('+|+') + '+|+' + event.target.name + '+';
                form_state[event.target.id] = currentFormStateParamValue;
            }
            else {
                // if not, set new category
                metric_params.append(event.target.id, event.target.name);
                form_state[event.target.id] = '+' + event.target.name + '+';
            }
        }
        else {
            // current value has not been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) - 1);
            if (metric_params.has(event.target.id)) {
                categoryValues = metric_params.get(event.target.id).split('|');
                var index = categoryValues.indexOf(event.target.name);
                if (index !== -1) {
                    categoryValues.splice(index, 1);
                }
                currentParamValue = categoryValues.join('|');
                metric_params.set(event.target.id, currentParamValue);
                currentFormStateParamValue = '+' + categoryValues.join('+|+') + '+';
                form_state[event.target.id] = currentFormStateParamValue;
                if (metric_params.get(event.target.id).length < 1) {
                    // delete category
                    metric_params.delete(event.target.id);
                    form_state[event.target.id] = '';
                }
            }
        }
        // update form state
        setFilters({
            ...filters,
            form_state: form_state,
            metric_params: metric_params
        });
        // call API
        setFilterMetricParams(metric_params);
        handleFilter(metric_params);
    }

    const handleFormChange = (event) => {
        // function to update filter categories
        let currentParamValue = '';
        let currentFormStateParamValue = '';
        let categoryValues = [];
        // get form state
        let metric_params = filters.metric_params;
        let form_state = filters.form_state;
        console.log("before:", metric_params.toString())
        console.log(form_state)
        if (event.target.checked) {
            // current value has been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) + 1);
            if (metric_params.has(event.target.id)) {
                // if so, add current value to category
                currentParamValue = metric_params.get(event.target.id) + '|' + event.target.name;
                metric_params.set(event.target.id, currentParamValue);
                // form state values are bookended with '+' to avoid filtering clashes
                currentFormStateParamValue = '+' + metric_params.get(event.target.id).split('|').join('+|+') + '+|+' + event.target.name + '+';
                form_state[event.target.id] = currentFormStateParamValue.replaceAll("+|++|+","+|+");
            }
            else {
                // if not, set new category
                metric_params.append(event.target.id, event.target.name);
                form_state[event.target.id] = '+' + event.target.name + '+';
            }
            console.log("after:", metric_params.toString())
            handleFilter(metric_params,form_state, metric_params,true);
                    
        }
        else {
            // current value has not been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) - 1);
            if (metric_params.has(event.target.id)) {
                categoryValues = metric_params.get(event.target.id).split('|');
                var index = categoryValues.indexOf(event.target.name);
                if (index !== -1) {
                    categoryValues.splice(index, 1);
                }
                currentParamValue = categoryValues.join('|');
                metric_params.set(event.target.id, currentParamValue);
                currentFormStateParamValue = '+' + categoryValues.join('+|+') + '+';
                form_state[event.target.id] = currentFormStateParamValue;
                if (metric_params.get(event.target.id).length < 1) {
                    // delete category
                    metric_params.delete(event.target.id);
                    form_state[event.target.id] = '';
                }  
                handleFilter(metric_params);         
           
        }

        }
        // update form state
        setFilters({
            ...filters,
            form_state: form_state,
            metric_params: metric_params
        });
        // call API
        setFilterMetricParams(metric_params);
       
    }


    const handleFilter = (query,form_state, metric_params,isChecked=false) => {
        setAppState({
            ...appState,
            loading: true,
        });

        api.getMetrics("?" + query.toString() + "&page=" + pageNo)
            .then((result) => {
                console.log(result.data)
                setMetrics(result.data.results);                
                setMetricCount(result.data.count); 
                setPrevPage(result.data.previous);
                setNextPage(result.data.next);
                setMaxPage(Math.ceil(result.data.count/10))
                if(isChecked){
                    getFilters(result.data.results,form_state, metric_params)                                                              
                }
                setAppState({
                    ...appState,
                    loading: false
                });
            }, (error) => {
                console.log(error);
            })
      
    };

    const clearFilters = () => {
        let form_state = {
            'use_case': '',
            'use_case_subcategory': '',
            'metric_type': '',
            'asset_class': '',
            'aggregation_level': '',
            'time_horizon': ''
        };
        let metric_params = new URLSearchParams();
        setFilters({
            ...filters,
            form_state: form_state,
            metric_params: metric_params
        });
        setFilterCount(0);
        // call API
        setFilterMetricParams(metric_params);
        handleFilter(metric_params);
    }

    const getFilters = (results,form_state, metric_params) => {
       /*  let form_state = {
            'use_case': '',
            'use_case_subcategory': '',
            'metric_type': '',
            'asset_class': '',
            'aggregation_level': '',
            'time_horizon': ''
        }; */
        //let metric_params = new URLSearchParams();
         //Set Filters based on result
        //Metric Types
        let metricTypes = []
        results.map(x=>{
            if(x.metric_types !== null){
            x.metric_types.map(y => metricTypes.push(y))
            }
            return null
        })
        const metric_types = [...new Set(metricTypes)]
        const metricType  = metric_types.filter(x=>x !== null).sort((a,b) => a > b ? 1 :-1)
        setFilterMetricTypes(metricType)
        let form_metric = ''
        metricType.map(item => 
            form_metric +=  item + '+|+',
            setFilterCount(parseInt(filter_count) + 1)
        )
        form_state['metric_type'] = '+' + form_metric + '+'
        metric_params.set('metric_type', form_metric.replaceAll('+',''));

        //Aggregation Level
        const aggregation_level = [...new Set(results.map(x=>x.aggregation_level))]
        const aggregationLevel = aggregation_level.filter(x=>x !== null).sort((a,b) => a > b ? 1 :-1)
        setFilterAggregationLevel(aggregationLevel)
        let form_aggLevel = ''
        aggregationLevel.map(item => 
            form_aggLevel += item + '+|+',
            setFilterCount(parseInt(filter_count) + 1)
        )
        form_state['aggregation_level']  = '+' +  form_aggLevel +'+'
        metric_params.set('aggregation_level', form_aggLevel.replaceAll('+',''));

        //Time horizon
        const time_horizon = [...new Set(results.map(x=>x.time_horizon))]
        const timeHorizon = time_horizon.filter(x=>x !== null).sort((a,b) => a > b ? 1 :-1)
        setFilterTimeHorizon(timeHorizon)
        let form_timeHorizon =''
        timeHorizon.map(item => 
            form_timeHorizon += item + '+|+',
            setFilterCount(parseInt(filter_count) + 1)
        )
        form_state['time_horizon'] = '+' + form_timeHorizon + '+'
        metric_params.set('time_horizon', form_timeHorizon.replaceAll('+',''));

        //Sub categories
        let useCase_subcategories = []
                        
        results.map(x=>x.use_case_subcategories.map(y=>{
            if(useCase_subcategories !== null){
            useCase_subcategories.push(y.name)
        }}))
        const subcategories = [...new Set(useCase_subcategories)]
        const use_case = metric_params.get('use_case')   
        if(use_case !== null){
            const use_cases = use_case.split('|')
            let form_sub = ''
            let final_use_sc = new Array()
            use_cases.forEach(item => {
                let sc = subcategories.filter(x=> x.includes(item))
                const use_sc = sc.filter(x=>x !== null).sort((a,b) => a > b ? 1 :-1)    
                use_sc.map(item => (
                    final_use_sc.push(item) ,
                    form_sub += item + '+|+',
                    setFilterCount(parseInt(filter_count) + 1)                  
                ))            
            })
            setFilterUseCaseSubcategories(final_use_sc)
            form_state['use_case_subcategory'] = '+' + form_sub + '+'
            metric_params.set('use_case_subcategory', form_sub.replaceAll('+',''));
        }else{
            const use_sc = subcategories.filter(x=>x !== null).sort((a,b) => a > b ? 1 :-1)
            setFilterUseCaseSubcategories(use_sc)
            let form_sub = ''
            use_sc.map(item => 
                form_sub += item + '+|+',
                setFilterCount(parseInt(filter_count) + 1)
            )
            form_state['use_case_subcategory'] = '+' + form_sub + '+'
            metric_params.set('use_case_subcategory', form_sub.replaceAll('+',''));
        }                
        //Use-Case
        /* const usecase = [...new Set(subcategories.map(x => x.split(' - ')[1]))]
        setFilterUseCase(usecase.filter(x=>x !== null).sort((a,b) => a > b ? 1 :-1))
        if(filter !== ''){
            form_state['use_case'] = '+' + filter + '+|+' + '+'
            metric_params.set('use_case', filter + '|');
            setFilterCount(parseInt(filter_count) + 1)
        } */

       // update form state
       setFilters({
        ...filters,
        form_state: form_state,
        metric_params: metric_params
        });
        setFilterMetricParams(metric_params);
    }

    useEffect(() => {
        (async () => {
            try {
                let form_state = {
                    'use_case': '',
                    'use_case_subcategory': '',
                    'metric_type': '',
                    'asset_class': '',
                    'aggregation_level': '',
                    'time_horizon': ''
                };
                let metric_params = new URLSearchParams();
                originalFilterMetricParams.forEach(function (value, key) {
                    form_state[key] = '+' + value.split('|').join('+|+') + '+';
                    metric_params.set(key, value);
                    value.split('|').forEach(function (item) {
                        setFilterCount(parseInt(filter_count) + 1);
                    });
                });
                
                setFilters({
                    ...filters,
                    form_state: form_state,
                    metric_params: metric_params
                });  
                const metricCategoriesRes = await api.getMetricCategories();
                const categories = metricCategoriesRes.data;
                setFilterCategories(categories);
                console.log("originalFilterMetricParams:", originalFilterMetricParams.toString())
                console.log("metric_params:", metric_params.toString())
                //await handleFilter(originalFilterMetricParams,form_state,metric_params,true);
                await handleFilter(originalFilterMetricParams,form_state,metric_params,true);
                setCategoriesLoading(false)


            } catch (error) {
                // login
                console.log(error);
            }
        })();
    }, [pageNo]);

    const listMetrics = metrics.map((item) =>
        <Card className="filterItemCard" style={{ marginTop: '15px' }} onClick={e => window.location.href = '/metric-detail/' + item.uuid}>
            <Card.Body>
                <h5>Metric: {item.name}</h5>
                <br />
                <Row style={{ fontSize: 12 }}>
                    <Col>Types: {item.metric_types ? item.metric_types.join(', ') : '-'}</Col>
                    <Col>Methodology: {item.methodology ? item.methodology : '-'}</Col>
                    <Col>Asset Classes: {item.asset_classes ? item.asset_classes.join(', ') : '-'}</Col>
                </Row>
            </Card.Body>
        </Card>
    );

    // styles

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad5 = {
        marginTop: '5px',
    };
    const topPad8 = {
        marginTop: '8px',
    };
    const topPad = {
        marginTop: '20px',
    };

    function setPageNumber(event, number) {
        //console.log(number)
        setPageNo(number)
    } 

    useEffect(() => {
        //console.log("metric_count:", metric_count)
        //console.log("page_num:", pageNo)
        //console.log("max_page_num:", maxPage)
        let active = pageNo;
        let maxPageNumber = maxPage
        let array_items = []
        for (let number = 1; number <= maxPageNumber; number++) {
            array_items.push(
                <Pagination.Item key={number} active={number === active} onClick={(event) => setPageNumber(event, number)}>
                {number}
                </Pagination.Item>,
            );
        }
        setItems(array_items)

    }, [metric_count, prevPage, nextPage, pageNo, maxPage])
    

    

    return (
        <Container fluid className="FilterMetrics">
            <Row>
                <Col xs={3} style={{ position: 'relative', height: size.height - 100, overflow: 'scroll' }}>
                    {categories_loading && (
                        <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24x' }} />
                    )}
                    {!categories_loading && (
                        <Form>
                            <Row>
                                {/* <Col><h4>{filter_count} Filter{filter_count != 1 ? "s" : ""}</h4></Col> */}
                                <Col style={{ marginTop: '4.5px' }}><a href="#" onClick={clearFilters}>Clear filter{filter_count != 1 ? "s" : ""}</a></Col>
                            </Row>
                            <Accordion defaultActiveKey="1" style={{ marginTop: '16px' }}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Metric Type</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_MetricTypes">
                                            {
                                                 filter_metric_types.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="metric_type"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.metric_type.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Use Case</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_UseCases">
                                            {
                                                filter_categories.use_cases.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="use_case"
                                                        name={item}
                                                        label={item}
                                                        checked={filters.form_state.use_case.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Use Case Subcategory</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_UseCaseSubcategories">
                                            {
                                                filter_useCase_subcategories.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="use_case_subcategory"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.use_case_subcategory.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/** 
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Asset Class</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_AssetClasses">
                                            {
                                                filter_categories.asset_classes.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="asset_class"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.asset_class.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                */}
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Aggregation Level / Resolution</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_AggregationLevel">
                                            {
                                                filter_aggregationLevel.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="aggregation_level"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.aggregation_level.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Time Horizon</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_TimeHorizon">
                                            {
                                                filter_time_horizon.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="time_horizon"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.time_horizon.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Form>
                    )}
                </Col>
                <Col xs={9} style={{ position: 'relative', height: size.height - 100, overflow: 'scroll' }}>
                    <Row style={bothPad}>
                        {appState.loading && (
                            <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24px' }} />
                        )}
                        {!appState.loading && (
                            <React.Fragment>
                                <Row>
                                    <h4>{metric_count}  Metric{metric_count != 1 ? "s" : ""}</h4>
                                    <Pagination>{items}</Pagination>
                                </Row>
                                {listMetrics}
                            </React.Fragment>
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}