import React, { useState, useEffect } from "react";
import './Filter.css';
import api from "../api/api";

// Import external components
import { useSearchParams } from "react-router-dom";
import { Accordion, Container, Row, Col, Form, Card, Spinner } from 'react-bootstrap';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

// functions 
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default function FilterDataItems() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const size = useWindowSize();

    // app state
    const [appState, setAppState] = useState({
        loading: true
    });
    const [categories_loading, setCategoriesLoading] = useState([false]);

    // regex variables
    const slash_regex = /(\S)\/(\S)/g;

    // data states
    const [data_item_count, setDataItemCount] = useState([]);
    const [data_items, setDataItems] = useState([]);
    const [filter_categories, setFilterCategories] = useState([]);
    const [filter_count, setFilterCount] = useState([0]);

    // filter form state and handlers
    const [filters, setFilters] = useState([]);

    // oiginal filter from browsing
    let [originalFilterDataItemParams, setFilterDataItemParams] = useSearchParams();

    const handleFormChange = (event) => {
        // function to update filter categories
        let currentParamValue = '';
        let currentFormStateParamValue = '';
        let categoryValues = [];
        // get form state
        let data_item_params = filters.data_item_params;
        let form_state = filters.form_state;
        if (event.target.checked) {
            // current value has been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) + 1);
            if (data_item_params.has(event.target.id)) {
                // if so, add current value to category
                currentParamValue = data_item_params.get(event.target.id) + '|' + event.target.name;
                data_item_params.set(event.target.id, currentParamValue);
                // form state values are bookended with '+' to avoid filtering clas
                currentFormStateParamValue = '+' + data_item_params.get(event.target.id).split('|').join('+|+') + '+|+' + event.target.name + '+';
                form_state[event.target.id] = currentFormStateParamValue;
            }
            else {
                // if not, set new category
                data_item_params.append(event.target.id, event.target.name);
                form_state[event.target.id] = '+' + event.target.name + '+';
            }
        }
        else {
            // current value has not been checked
            // ascertain if there are existing values in the category
            setFilterCount(parseInt(filter_count) - 1);
            if (data_item_params.has(event.target.id)) {
                categoryValues = data_item_params.get(event.target.id).split('|');
                var index = categoryValues.indexOf(event.target.name);
                if (index !== -1) {
                    categoryValues.splice(index, 1);
                }
                currentParamValue = categoryValues.join('|');
                data_item_params.set(event.target.id, currentParamValue);
                currentFormStateParamValue = '+' + categoryValues.join('+|+') + '+';
                form_state[event.target.id] = currentFormStateParamValue;
                if (data_item_params.get(event.target.id).length < 1) {
                    // delete category
                    data_item_params.delete(event.target.id);
                    form_state[event.target.id] = '';
                }
            }
        }
        // update form state
        setFilters({
            ...filters,
            form_state: form_state,
            data_item_params: data_item_params
        });
        // call API
        setFilterDataItemParams(data_item_params);
        handleFilter(data_item_params);
    }

    const handleFilter = (query) => {
        setAppState({
            ...appState,
            loading: true,
        });
        api.getDataItems("?" + query.toString())
            .then((result) => {
                setDataItems(result.data.results);
                setDataItemCount(result.data.count);
                setAppState({
                    ...appState,
                    loading: false
                });
            }, (error) => {
                console.log(error);
            })
            ;
    };

    const clearFilters = () => {
        let form_state = {
            'use_case': '',
            'use_case_subcategory': '',
            'aggregation_level': '',
            'time_horizon': '',
            'availability': '',
            'accessibility': ''
        };
        let data_item_params = new URLSearchParams();
        setFilters({
            ...filters,
            form_state: form_state,
            data_item_params: data_item_params
        });
        setFilterCount(0);
        // call API
        setFilterDataItemParams(data_item_params);
        handleFilter(data_item_params);
    }

    useEffect(() => {
        (async () => {
            try {
                let form_state = {
                    'use_case': '',
                    'use_case_subcategory': '',
                    'aggregation_level': '',
                    'time_horizon': '',
                    'availability': '',
                    'accessibility': ''
                };
                let data_item_params = new URLSearchParams();
                originalFilterDataItemParams.forEach(function (value, key) {
                    form_state[key] = '+' + value.split('|').join('+|+') + '+';
                    data_item_params.set(key, value);
                    value.split('|').forEach(function (item) {
                        setFilterCount(parseInt(filter_count) + 1);
                        console.log('current - item: ' + item + ', count: ' + filter_count);
                    });
                });
                setFilters({
                    ...filters,
                    form_state: form_state,
                    data_item_params: data_item_params
                });
                const dataItemCategoriesRes = await api.getDataItemCategories();
                const categories = dataItemCategoriesRes.data;
                await handleFilter(originalFilterDataItemParams);
                setFilterCategories(categories);
                setCategoriesLoading(false)


            } catch (error) {
                // login
                console.log(error);
            }
        })();
    }, []);

    const listDataItems = data_items.map((item) =>
        <Card className="filterItemCard" style={{ marginTop: '15px' }} onClick={e => window.location.href = '/data-item-detail/' + item.uuid}>
            <Card.Body>
                <h5>Data Item: {item.name}</h5>
                Description: {item.description ? item.description : '-'}
                <br /><br />
                <Row style={{ fontSize: 12 }}>
                    <Col>Data Provider: {item.data_provider_name ? item.data_provider_name : '-'}</Col>
                    <Col>Availability: {item.availability ? item.availability : '-'}</Col>
                    <Col>Accessibility: {item.accessibility ? item.accessibility : '-'}</Col>
                </Row>
            </Card.Body>
        </Card>
    );

    // styles

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad5 = {
        marginTop: '5px',
    };
    const topPad8 = {
        marginTop: '8px',
    };
    const topPad = {
        marginTop: '20px',
    };

    return (
        <Container fluid className="FilterDataItems">
            <Row>
                <Col xs={3} style={{ position: 'relative', height: size.height - 100, overflow: 'scroll' }}>
                    {categories_loading && (
                        <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24px' }} />
                    )}
                    {!categories_loading && (
                        <Form>
                            <Row>
                                <Col><h4>{filter_count} Filter{filter_count != 1 ? "s" : ""}</h4></Col>
                                <Col style={{marginTop: '4.5px'}}><a href="#" onClick={clearFilters}>Clear filter{filter_count != 1 ? "s" : ""}</a></Col>
                            </Row>
                            <Accordion defaultActiveKey="0" style={{ marginTop: '16px' }}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Use Case</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_UseCases">
                                            {
                                                filter_categories.use_cases.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="use_case"
                                                        name={item}
                                                        label={item}
                                                        checked={filters.form_state.use_case.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Use Case Subcategory</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_UseCaseSubcategories">
                                            {
                                                filter_categories.use_case_subcategories.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="use_case_subcategory"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.use_case_subcategory.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Aggregation Level / Resolution</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_AggregationLevel">
                                            {
                                                filter_categories.aggregation_levels.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="aggregation_level"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.aggregation_level.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Time Horizon</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_TimeHorizon">
                                            {
                                                filter_categories.time_horizons.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="time_horizon"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.time_horizon.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Availability</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_Availability">
                                            {
                                                filter_categories.availabilities.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="availability"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.availability.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Accessibility</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox_Accessibility">
                                            {
                                                filter_categories.accessibilities.map((item) =>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="accessibility"
                                                        name={item}
                                                        label={item.replace(slash_regex, '$1 / $2')}
                                                        checked={filters.form_state.accessibility.includes('+' + item + '+')}
                                                        style={{ marginTop: '6px' }}
                                                        onChange={handleFormChange}
                                                    />
                                                )
                                            }
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                                */}
                            </Accordion>
                        </Form>
                    )}
                </Col>
                <Col xs={9} style={{ position: 'relative', height: size.height - 100, overflow: 'scroll' }}>
                    <Row style={bothPad}>
                        {appState.loading && (
                            <Spinner animation="border" variant="primary" size="lg" style={{ marginTop: '24px' }} />
                        )}
                        {!appState.loading && (
                            <React.Fragment>
                                <Row>
                                    <h4>{data_item_count} Data Item{data_item_count != 1 ? "s" : ""}</h4>
                                </Row>
                                {listDataItems}
                            </React.Fragment>
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}