import React, { useState } from "react";

// Import external components
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Table } from 'react-bootstrap';

// Import images and icons
import { BsSearch } from 'react-icons/bs';

// Import internal components
import BrowseUseCases from "./BrowseUseCases";
import BrowseMetrics from "./BrowseMetrics";
import './Browse.css';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function Browse() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const [appState, setAppState] = useState({
        display: "hide",
        loading: false,
    });

    // styles

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad5 = {
        marginTop: '5px',
    };
    const topPad8 = {
        marginTop: '8px',
    };
    const topPad = {
        marginTop: '20px',
    };
    const primaryColor = {
        color: '#1496A5',
    }

    let { category } = useParams();

    const [searchQuery, setSearchQuery] = useState("");

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            let res;
            setAppState({ ...appState, loading: true });
            console.log("target name: " + e.target.name);
            switch (e.target.name) {
                case "FilterSearch":
                    window.location.href = "/search?query=" + encodeURI(searchQuery);
                    break;
                default:
                    break;
            }
        } catch (error) {
            setAppState({ ...appState, loading: false });
            alert(error.response.data.error);
        }
    };

    return (
        <Container fluid className="Browse">
            <h5 style={leftPad}>Explore the data items curated for the assessment of climate-related risks and opportunities in the financial sector.</h5>
            <br />
            <Form>
                <Row style={{ marginLeft: '80px', marginRight: '80px', }}>
                    <Col md="auto"><BsSearch size={24} style={topPad5} /></Col>
                    <Col><Form.Control type="text" placeholder="Search" onChange={e => setSearchQuery(e.target.value)} /></Col>
                    <Col md="auto">
                        <Button className="text-center" variant="primary" type="submit" name="FilterSearch" onClick={(e) => handleFormSubmit(e)}>
                            <span style={{ color: "white" }}>Search</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
            <br />
            {category == "use-cases" && (
                <BrowseUseCases />
            )}
            {category == "metrics" && (
                <BrowseMetrics />
            )}
            {category != "use-cases" && category != "metrics" && (
                <Row>
                    <Col></Col>
                    <Col md="auto">
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td><h5 style={{ marginTop: '15px' }}>Browse by:</h5></td>
                                    <td><div className="itemCard" style={{ background: '#cbe0c8', height: '4rem', width: '14rem' }} onClick={e => window.location.href = '/browse/use-cases/'}><h5><b>Use Cases</b></h5></div></td>
                                    <td><div className="itemCard" style={{ background: '#cbe0c8', height: '4rem', width: '14rem' }} onClick={e => window.location.href = '/browse/metrics/'}><h5><b>Metrics</b></h5></div></td>
                                </tr>
                                <tr>
                                    <td><h5 style={{ marginTop: '15px' }}>Suggest:</h5></td>
                                    <td><div className="itemCard" style={{ background: '#cbe0c8', height: '4rem', width: '14rem' }} onClick={e => window.location.href = '/suggest-new/metric/'}><h5><b>New Metric</b></h5></div></td>
                                    <td><div className="itemCard" style={{ background: '#cbe0c8', height: '4rem', width: '14rem' }} onClick={e => window.location.href = '/suggest-new/data-item/'}><h5><b>New Data Item</b></h5></div></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col></Col>
                </Row>
            )}
        </Container>
    )
}