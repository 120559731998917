import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";

// Import images and icons
import { BsSearch } from 'react-icons/bs';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function FAQ() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    const [appState, setAppState] = useState({
        display: "hide",
        loggedin: false,
        loading: false,
    });

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad8 = {
        marginTop: '5px',
    };
    const topPad = {
        marginTop: '20px',
    };
    const primaryColor = {
        color: '#1496A5',
    }

    return (
        <Container fluid className="FAQ" style={bothPad}>
            <h5 className="text-primary">Placeholder for FAQ</h5>
        </Container>
    )
}