import React, { useState, useEffect } from "react";
import './Detail.css';
import api from "../api/api";

// Import external components
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col, Table } from 'react-bootstrap';

// Matomo
import { useMatomo } from '@datapunt/matomo-tracker-react'

// functions 
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default function DataItemDetail() {

    // Matomo
    const { trackPageView, trackEvent } = useMatomo()

    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])

    // app state
    const [appState, setAppState] = useState({
        loading: true
    });
    const size = useWindowSize();
    const [data_item, setDataItem] = useState([]);

    // param
    let { dataItemUUID } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const dataItemDetailRes = await api.getDataItemDetail(dataItemUUID);
                setDataItem(dataItemDetailRes.data);
                setAppState({
                    ...appState,
                    loading: false,
                });
            } catch (error) {
                // login
                console.log(error);
            }
        })();
    }, []);

    // styles
    const leftPad = {
        marginLeft: '4px',
    };
    const rightPad = {
        marginRight: '4px',
    };
    const bothPad = {
        marginLeft: '4px',
        marginRight: '4px',
    };
    const topPad5 = {
        marginTop: '5px',
    };
    const topPad6 = {
        marginTop: '6px',
    };
    const topPad8 = {
        marginTop: '8px',
    };
    const topPad12 = {
        marginTop: '12px',
    };
    const topPad = {
        marginTop: '20px',
    };

    function getNumberOfMetricsWhereUsed(item) {
        if (item.metrics_where_used) {
            return item.metrics_where_used.length;
        }
        else {
            return 0;
        }
    }

    return (
        <Container fluid className="DataItemDetail">
            <Row style={bothPad}>
                <h5 class="text-primary">Data Item</h5>
                <h4>{data_item.name}</h4>
                <Col xs={6} style={{ position: 'relative', height: size.height - 200, overflow: 'scroll' }}>
                    <Table borderless>
                        <tbody>
                            <tr>
                                <td><b>Description:</b></td>
                                <td>{data_item.description ? data_item.description : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Data provider:</b></td>
                                <td>{data_item.data_provider_url ? <a href={data_item.data_provider_url} target="_blank">{data_item.data_provider_name}</a> : data_item.data_provider_name ? data_item.data_provider_name : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Type of data:</b></td>
                                <td>{data_item.type_of_data ? data_item.type_of_data : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Unit:</b></td>
                                <td>{data_item.unit ? data_item.unit : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Aggregation level / Resolution:</b></td>
                                <td>{data_item.aggregation_level ? data_item.aggregation_level : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Data Quality:</b></td>
                                <td>{data_item.data_quality ? data_item.data_quality : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Time horizon:</b></td>
                                <td>{data_item.time_horizon ? data_item.time_horizon : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Availability:</b></td>
                                <td>{data_item.availability ? data_item.availability : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Accessibility:</b></td>
                                <td>{data_item.accessibility ? data_item.accessibility : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Frequency of update:</b></td>
                                <td>{data_item.frequency_of_update ? data_item.frequency_of_update : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Timeliness:</b></td>
                                <td>{data_item.timeliness ? data_item.timeliness : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Additional Comments:</b></td>
                                <td>{data_item.additional_comments ? data_item.additional_comments : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>Start date:</b></td>
                                <td>{data_item.start_date ? data_item.start_date : '-'}</td>
                            </tr>
                            <tr>
                                <td><b>End date:</b></td>
                                <td>{data_item.end_date ? data_item.end_date : '-'}</td>
                            </tr>
                        </tbody>
                    </Table>

                </Col>
                <Col xs={6}>
                    <Card className="itemDetail">
                        <Card.Body>
                            <Row>
                                <Col md="auto" className="alignBottom"><h1 class="text-primary">{getNumberOfMetricsWhereUsed(data_item)}</h1></Col>
                                <Col className="alignBottom"><h6>metric{getNumberOfMetricsWhereUsed(data_item) != 1 ? "s" : ""} {getNumberOfMetricsWhereUsed(data_item) != 1 ? "are" : "is"} constructed from this data item.</h6></Col>
                            </Row>
                            <Row>
                                <Col style={{ marginLeft: '15px', marginRight: '15px', position: 'relative', height: size.height - 250, overflow: 'scroll' }}>
                                    {data_item.metrics_where_used && (
                                        <React.Fragment>
                                            {
                                                data_item.metrics_where_used.map((item) =>
                                                    <Card className="detailCard" onClick={e => window.location.href = '/metric-detail/' + item.uuid}>
                                                        <Card.Body>
                                                            <h6>Metric: {item.name}</h6>
                                                            <p><em>Metric classification: {item.metric_types.join(', ')}</em></p>
                                                            <p><em>Methodology: {item.methodology}</em></p>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            }
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}