import React, { useState, useEffect } from "react";

// Import style sheets
import "../src/style.scss";
import "./App.css";

// Import external components
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Import internal components
import api from "./components/api/api";
import Login from "./components/Login/Login";
import Browse from "./components/Browse/Browse";
import Glossary from "./components/Glossary/Glossary";
import FAQ from "./components/FAQ/FAQ";
import AboutUs from "./components/AboutUs/AboutUs";
import SuggestNew from "./components/SuggestNew/SuggestNew";
import SearchGeneral from "./components/Search/SearchGeneral";
import FilterUseCaseSubcategory from "./components/Filter/FilterUseCaseSubcategory";
import FilterDataItems from "./components/Filter/FilterDataItems";
import FilterMetrics from "./components/Filter/FilterMetrics";
import FilterSearch from "./components/Filter/FilterSearch";
import MetricDetail from "./components/Detail/MetricDetail";
import DataItemDetail from "./components/Detail/DataItemDetail";
import FilterUseCaseMetrics from "./components/Filter/FilterUseCaseMetrics";
import BrowseFilterUseCaseMetrics from "./components/Filter/BrowseFilterUseCaseMetrics";
import BrowseFilterMetrics from "./components/Filter/BrowseFilterMetrics";
import Disclaimer from "./components/Disclaimer/Disclaimer";

// Import images and icons
import ngfsLogo from "./components/Images/ngfsLogo.svg";
import backgroundImage from "./components/Images/backgroundImageCrop.jpg";
import {
  Button,
  Container,
  Navbar,
  Nav,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

// Matomo
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
  urlBase: "https://info.for-our.info",
  siteId: 3,
});

// variables to switch for NGFS and climte data repository
// const title="NGFS Bridging Data Gaps Repository";
const title = "The NGFS Directory";

function App() {
  const [appState, setAppState] = useState({
    loggedin: false,
    disclaimer: true,
  });

  useEffect(() => {
    (async () => {
      try {
        //const userDetailRes = await api.getUserDetail();
        setAppState({
          ...appState,
          display: "show",
          //loggedin: localStorage.getItem("dkpLoggedin"),
          //username: userDetailRes.data.username,
          //user_first_name: userDetailRes.data.first_name,
          //user_last_name: userDetailRes.data.last_name,
          //disclaimer: localStorage.getItem("ngfsdisclaimer"),
        });
      } catch (error) {
        // login
        console.log(error);
      }
    })();
  }, []);

  const setDisclaimer = () => {
    setAppState({
      ...appState,
      disclaimer: true,
    });
    localStorage.setItem("ngfsdisclaimer", true);
  };

  const removeDisclaimer = () => {
    setAppState({
      ...appState,
      disclaimer: false,
    });
    localStorage.removeItem("ngfsdisclaimer");
  };

  const handleLogout = async () => {
    try {
      setAppState({ ...appState, loggedin: false, disclaimer: false });
      localStorage.removeItem("dkpLoggedin");
      localStorage.removeItem("ngfsdisclaimer");
      await api.logout({});
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
    }
  };

  const leftPad = {
    marginLeft: "20px",
  };
  const rightPad = {
    marginRight: "20px",
  };
  const bothPad = {
    marginLeft: "20px",
    marginRight: "20px",
  };
  const topPad8 = {
    marginTop: "5px",
  };
  const topPad = {
    marginTop: "20px",
  };
  const primaryColor = {
    color: "#1496A5",
  };
  const topImage = {
    backgroundImage: `url(${backgroundImage})`,
    background_size: "cover",
  };

  console.log("disclaimer:", appState.disclaimer);
  return (
    <MatomoProvider value={instance}>
      <div className="App">
        {/* {(!appState.loggedin && !appState.disclaimer) ? (
          <Login />
        ) : ((appState.loggedin && !appState.disclaimer) ? (
          <Disclaimer setDisclaimer={setDisclaimer}/>
        ) : "")} */}

        {!appState.disclaimer ? (
          <Disclaimer setDisclaimer={setDisclaimer} />
        ) : (
          ""
        )}

        {appState.disclaimer && (
          <div>
            {/*<Navbar expand="sm" className="mb-3">*/}
            <Navbar expand="sm" style={topImage}>
              <Navbar.Brand href="/browse" style={leftPad}>
                <h3 className="text-light shadow-text" style={topPad}>
                  {title}
                </h3>
              </Navbar.Brand>
              {/*
            <Navbar.Collapse className="justify-content-end" style={rightPad}>
              <img src={ngfsLogo} width={120} />
            </Navbar.Collapse>
             */}
            </Navbar>
            <Navbar bg="primary" expand="sm" className="mb-3">
              <Navbar.Collapse style={leftPad}>
                <Nav.Link href="/browse" className="linkText">
                  Browse
                </Nav.Link>
                <Nav.Link href="/glossary" className="linkText">
                  Glossary
                </Nav.Link>
                <Nav.Link href="/faq" className="linkText">
                  FAQ
                </Nav.Link>
                <Nav.Link href="/about-us" className="linkText">
                  About Us
                </Nav.Link>
                {/* <Nav.Link
                  target="_blank"
                  href="https://www.banque-france.fr/en/webform/survey-consultation-ngfs-directory"
                  className="linkText"
                >
                  Feedback
                </Nav.Link> */}
              </Navbar.Collapse>
              {/* <Navbar.Collapse className="justify-content-end" style={rightPad}>
                 <Navbar.Text className="text-light" style={rightPad}>Welcome, {appState.user_first_name} {appState.user_last_name} <FaUserCircle size={28} /></Navbar.Text> 
                <Button variant="light" onClick={handleLogout}>
                  <span class="text-primary">Log Out</span>
                </Button>
              </Navbar.Collapse> */}
            </Navbar>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Browse />} />
                <Route path="search/*" element={<FilterSearch />} />
                <Route path="browse" element={<Browse />}>
                  <Route path="" element={<Browse />} />
                  <Route path=":category" element={<Browse />} />
                </Route>
                <Route
                  path="filter-use-case"
                  element={<FilterUseCaseSubcategory />}
                >
                  <Route
                    path=":useCaseUUID"
                    element={<FilterUseCaseSubcategory />}
                  />
                </Route>
                <Route
                  path="filter-data-items/*"
                  element={<FilterDataItems />}
                ></Route>
                <Route
                  path="filter-metrics/*"
                  element={<FilterMetrics />}
                ></Route>
                <Route
                  path="browse-filter-metrics/*"
                  element={<BrowseFilterMetrics />}
                ></Route>
                <Route
                  path="filter-usecase-metrics/*"
                  element={<FilterUseCaseMetrics />}
                ></Route>
                <Route
                  path="browse-filter-usecase-metrics/*"
                  element={<BrowseFilterUseCaseMetrics />}
                ></Route>
                <Route path="metric-detail" element={<MetricDetail />}>
                  <Route path=":metricUUID" element={<MetricDetail />} />
                </Route>
                <Route path="data-item-detail" element={<DataItemDetail />}>
                  <Route path=":dataItemUUID" element={<DataItemDetail />} />
                </Route>
                <Route path="glossary" element={<Glossary />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="suggest-new" element={<SuggestNew />}>
                  <Route path=":item" element={<SuggestNew />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </div>
        )}
      </div>
    </MatomoProvider>
  );
}

export default App;
